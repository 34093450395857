import { VehicleNodes } from "../../contracts/vehicle"
import "./Vehicle.scss"
import { Col } from "antd"
import { GatsbyImage } from "gatsby-plugin-image"
import { IGatsbyImageData } from "gatsby-plugin-image/dist/src/components/gatsby-image.browser"
import React from "react"

export interface Props {
    vehicleNodes: VehicleNodes
    vehicleImg: IGatsbyImageData | null
    location: Location
}

export const VehicleItem = (props: Props) => {
    const item = props.vehicleNodes
    const vehicleImg = props.vehicleImg
    const ivechCat = item.node.ivechPlugin.websiteCategory ?? 1
    let showPrices = false
    // if (
    // 	item.node.vehicleDetails.vehicleDailyRate !== null ||
    // 	item.node.vehicleDetails.vehicleWeekendRate !== null ||
    // 	item.node.vehicleDetails.vehicleWeeklyRate !== null ||
    // 	item.node.vehicleDetails.vehicle36DaysRate !== null
    // ) {
    // 	showPrices = true;
    // }
    return (
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <div className="c_vehicles__item">
                {vehicleImg !== null ? (
                    <GatsbyImage
                        image={vehicleImg}
                        alt={item.node.title}
                        title={item.node.title}
                    />
                ) : (
                    ""
                )}
                <div className="c_vehicles__contentWrap">
                    <p className="c_vehicles__title">{item.node.title}</p>
                    <div
                        className="c_vehicles__content"
                        dangerouslySetInnerHTML={{ __html: item.node.content }}
                    />
                    <ul>
                        {item.node.vehicleDetails.vehicleModel !== null &&
                        item.node.vehicleDetails.vehicleModel.toUpperCase() !==
                            "N/A" ? (
                            <li>{item.node.vehicleDetails.vehicleModel}</li>
                        ) : (
                            ""
                        )}
                        {item.node.vehicleDetails.vehicleTransmission !==
                            null &&
                        item.node.vehicleDetails.vehicleTransmission.toUpperCase() !==
                            "N/A" ? (
                            <li>
                                {item.node.vehicleDetails.vehicleTransmission}
                            </li>
                        ) : (
                            ""
                        )}
                        {item.node.vehicleDetails.vehiclePassengers !== null ? (
                            <li>
                                {item.node.vehicleDetails.vehiclePassengers}{" "}
                                Passengers
                            </li>
                        ) : (
                            ""
                        )}
                        {item.node.vehicleDetails.vehicleEngineSize !== null &&
                        item.node.vehicleDetails.vehicleEngineSize.toUpperCase() !==
                            "N/A" ? (
                            <li>
                                {item.node.vehicleDetails.vehicleEngineSize}
                            </li>
                        ) : (
                            ""
                        )}
                        {item.node.vehicleDetails.vehiclePayload !== null &&
                        item.node.vehicleDetails.vehiclePayload.toUpperCase() !==
                            "N/A" ? (
                            <li>
                                Payload:{" "}
                                {item.node.vehicleDetails.vehiclePayload}
                            </li>
                        ) : (
                            ""
                        )}
                        {item.node.vehicleDetails.vehicleMpg !== null ? (
                            <li>
                                Average MPG:{" "}
                                {item.node.vehicleDetails.vehicleMpg}mpg
                            </li>
                        ) : (
                            ""
                        )}
                        {item.node.vehicleDetails.vehicleInteriorLength !==
                            null &&
                        item.node.vehicleDetails.vehicleInteriorLength.toUpperCase() !==
                            "N/A" ? (
                            <li>
                                Interior length:{" "}
                                {item.node.vehicleDetails.vehicleInteriorLength}
                            </li>
                        ) : (
                            ""
                        )}
                    </ul>
                </div>
                {showPrices && (
                    <div className="c_vehicles__rates">
                        <span>Rates starting from</span>
                        <ul>
                            {item.node.vehicleDetails.vehicleDailyRate !==
                            null ? (
                                <li>
                                    <span>Daily</span>
                                    <span>
                                        £
                                        {item.node.vehicleDetails.vehicleDailyRate.toFixed(
                                            2
                                        )}
                                    </span>
                                </li>
                            ) : (
                                <li>
                                    <span>Daily</span>
                                    <span>N/A</span>
                                </li>
                            )}
                            {item.node.vehicleDetails.vehicleWeekendRate !==
                            null ? (
                                <li>
                                    <span>Weekend</span>
                                    <span>
                                        £
                                        {item.node.vehicleDetails.vehicleWeekendRate.toFixed(
                                            2
                                        )}
                                    </span>
                                </li>
                            ) : (
                                <li>
                                    <span>Weekend</span>
                                    <span>N/A</span>
                                </li>
                            )}
                            {item.node.vehicleDetails.vehicleWeeklyRate !==
                            null ? (
                                <li>
                                    <span>Weekly</span>
                                    <span>
                                        £
                                        {item.node.vehicleDetails.vehicleWeeklyRate.toFixed(
                                            2
                                        )}
                                    </span>
                                </li>
                            ) : (
                                <li>
                                    <span>Weekly</span>
                                    <span>N/A</span>
                                </li>
                            )}
                            {item.node.vehicleDetails.vehicle36DaysRate !==
                            null ? (
                                <li>
                                    <span>Monthly</span>
                                    <span>
                                        £
                                        {item.node.vehicleDetails.vehicle36DaysRate.toFixed(
                                            2
                                        )}
                                    </span>
                                </li>
                            ) : (
                                <li>
                                    <span>Monthly</span>
                                    <span>N/A</span>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
                <a
                    className="c_vehicles__cta btn btn-primary"
                    href={"/book-now?type=" + ivechCat}
                >
                    Book Now
                </a>
            </div>
        </Col>
    )
}

export default VehicleItem
