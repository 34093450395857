import React, {useEffect} from 'react';

import {graphql} from 'gatsby';

import { Row, Col } from 'antd';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import {Page} from '../contracts/page';
import {Vehicle, VehicleNodes} from '../contracts/vehicle';

import {IGatsbyImageData} from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import ContentBlockHtml from '../components/ContentBlock';
import FaqBlock from '../components/Faqs';
import Hero from '../components/Hero';
import ReviewsBlock from '../components/Reviews';
import VehicleItem from '../components/Vehicle';

import '../styles/vehicle-page.scss';

export interface Props {
	data: {
		wpPage: Page;
		allWpVehicle: Vehicle;
	};
	location: Location;
}

export const HirePage = (props: Props) => {
	const page     = props.data.wpPage;
	const vehicle  = props.data.allWpVehicle;
	const ivechCat = page.ivechPlugin.websiteCategory === null ? 0 : page.ivechPlugin.websiteCategory;
	const fluid: IGatsbyImageData | null = (
		props.data &&
		page &&
		page.heroBanner &&
		page.heroBanner.bannerImage &&
		page.heroBanner.bannerImage.localFile &&
		page.heroBanner.bannerImage.localFile.childImageSharp &&
		page.heroBanner.bannerImage.localFile.childImageSharp.gatsbyImageData
	) ? page.heroBanner.bannerImage.localFile.childImageSharp.gatsbyImageData : null;
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true
				}
			}
		]
	};
	useEffect(() => {
		const heights = document.querySelectorAll('.c_vehicles__contentWrap');
		let finalHeight = 0;
		heights.forEach((height) => {
			// @ts-ignore
			if (height.offsetHeight > finalHeight) {
				// @ts-ignore
				finalHeight = height.offsetHeight;
			}
		});
		finalHeight += 60;
		function updateSize() {
			heights.forEach((height) => {
				// @ts-ignore
				height.style.height = (finalHeight) + 'px';
			});
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return (
		<Layout location={props.location}>
			<SEO title={page.seo.title} description={page.seo.metaDesc} />
			<Row type="flex">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} id="primary" className="content-area">
					<div className="hire">
						<div className="top-banner">
							{(page.heroBanner && page.heroBanner.showBanner === 'yes' && fluid !== null) && (
								<Hero location={props.location} heroBanner={page.heroBanner} fluid={fluid}/>
							)}
							{(page.bookNowBlock && page.bookNowBlock.showBookNowBlock === 'yes') ? (
								<div className="c_booking__wrap">
									<iframe sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin" className={'c_booking c_booking--vehicle ant-row'} src={'https://dmg.bookmyhire.co.uk/?i=iframe&cat=' + ivechCat} width={'65%'} height={'490px'}/>
								</div>
							) : ''}
						</div>
						<Row type="flex" className="c_vehicles page-gutter">
							{(page.hireCategoryName && page.hireCategoryName.hireCategoryTitle) ? (
								<p>{page.hireCategoryName.hireCategoryTitle}</p>
							) : ''}
							{vehicle && vehicle.edges.length && vehicle.edges.map((item: VehicleNodes, itemIndex: number) => {
								const vehicleImg: IGatsbyImageData | null = (
									item.node.vehicleDetails.vehicleImage &&
									item.node.vehicleDetails.vehicleImage.localFile &&
									item.node.vehicleDetails.vehicleImage.localFile.childImageSharp &&
									item.node.vehicleDetails.vehicleImage.localFile.childImageSharp.gatsbyImageData
								) ? item.node.vehicleDetails.vehicleImage.localFile.childImageSharp.gatsbyImageData : null;
								return (
									<VehicleItem key={itemIndex} location={props.location} vehicleImg={vehicleImg} vehicleNodes={item}/>
								);
							})}
						</Row>
						{(page.reviews && page.reviews.showReviews === 'yes') ? (
							<ReviewsBlock location={props.location} reviews={page.reviews} settings={settings}/>
						) : ''}
						{(page.faqs && page.faqs.showFaqs === 'yes') ? (
							<FaqBlock location={props.location} showHeading={true} faqs={page.faqs} bgWhite={false}/>
						) : ''}
						{(page.contentBlock && page.contentBlock.showContentBlock === 'yes') ? (
							<ContentBlockHtml location={props.location} contentBlock={page.contentBlock}/>
						) : ''}
					</div>
				</Col>
			</Row>
		</Layout>
	);
};

export default HirePage;

export const query = graphql`
	query($id: String!, $categories: [String]) {
      wpPage(id: { eq: $id }) {
        id
        bookNowBlock {
          showBookNowBlock
        }
		bannerContentBlock {
		  bannerContentBackgroundColour
		  bannerContentContent
		  bannerContentCtaLink
		  bannerContentCtaText
		  bannerContentShowCta
		  bannerContentSubtitle
		  bannerContentSubtitleColour
		  bannerContentTitle
		  bannerContentTitleColour
		  fieldGroupName
		  showBannerContentBlock
		  bannerContentImage {
		    altText
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
		contentBlock {
		  contentContent
		  contentSubtitle
		  contentTitle
		  fieldGroupName
		  showContentBlock
		}
		ctas {
		  ctas {
			fieldGroupName
			ctaTitle
			ctaIcon {
			  altText
			  localFile {
			    publicURL
			    childImageSharp {
				  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
			    }
			  }
		    }
			ctaLink {
			  target
			  title
			  url
			}
		  }
		  fieldGroupName
		}
		faqs {
		  faqs {
			question
			fieldGroupName
			answer
		  }
		  showFaqs
		  fieldGroupName
		}
		heroBanner {
		  bannerContent
		  bannerLayout
		  bannerTextColour
		  fieldGroupName
		  bannerTitle
		  showBanner
		  bannerImage {
			altText
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
		hireCategoryName {
		  hireCategoryTitle
		}
		hireTypes {
		  fieldGroupName
		  hireType {
			fieldGroupName
			hireContent
			typeOfHire
			hireLink {
			  ... on WpPage {
				link
			  }
			}
			hireImage {
			  altText
			  caption
			  description
			  fileSize
			  localFile {
				childImageSharp {
				  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
				}
			  }
			}
		  }
		}
		ivechPlugin {
		  websiteCategory
		}
		reviews {
		  showReviews
		  reviews {
			reviewContent
			reviewName
			reviewStars
		  }
		}
		seo {
		  metaDesc
		  metaKeywords
		  metaRobotsNoindex
		  metaRobotsNofollow
		  opengraphAuthor
		  opengraphDescription
		  opengraphModifiedTime
		  opengraphPublishedTime
		  opengraphPublisher
		  opengraphSiteName
		  opengraphTitle
		  opengraphType
		  opengraphUrl
		  readingTime
		  title
		  twitterTitle
		  schema {
			articleType
			pageType
			raw
		  }
		  twitterDescription
		  twitterImage {
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
      }
      allWpVehicle(filter: {categories: {nodes: {elemMatch: {name: {in: $categories}}}}}) {
		edges {
		  node {
			content
			slug
			title
			uri
			ivechPlugin {
              websiteCategory
            }
			vehicleDetails {
			  vehicle36DaysRate
			  vehicleDailyRate
			  vehicleEngineSize
			  vehicleInteriorLength
			  vehicleModel
			  vehicleMpg
			  vehiclePassengers
			  vehiclePayload
			  vehicleTransmission
			  vehicleWeekendRate
			  vehicleWeeklyRate
			  vehicleImage {
				altText
				localFile {
				  childImageSharp {
					gatsbyImageData(
					  layout: CONSTRAINED
					  placeholder: BLURRED
					  quality: 100
					  webpOptions: {quality: 100}
					  pngOptions: {quality: 100}
					  jpgOptions: {quality: 100}
					)
				  }
				}
			  }
			}
		  }
		}
	  }
    }
`;
